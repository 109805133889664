import React, { useState } from 'react';
import introduce1 from '../../images_webp/index-introduce-1.webp'
import introduce2 from '../../images_webp/index-introduce-2.webp'
import introduce3 from '../../images_webp/index-introduce-3.webp'
import introduce4 from '../../images_webp/index-introduce-4.webp'
import featuredproduct1 from '../../images_webp/index-featured-product-1.webp'
import featuredproduct2 from '../../images_webp/index-featured-product-2.webp'
import logo1 from '../../images_webp/home9.webp'
import logo2 from '../../images_webp/home8.webp'
import logo3 from '../../images_webp/home10.webp'
import logo4 from '../../images_webp/home11.webp'
import logo5 from '../../images_webp/home12.webp'
import logo6 from '../../images_webp/home13.webp'
import logo7 from '../../images_webp/home14.webp'
import logo8 from '../../images_webp/logo-bkk.webp'
import home from '../../images_webp/bg-banner.webp'
import Layoutas from '../components/Layout';
import useTranslation from '../hook/useTranslation'

import './index.css';
import { navigate } from 'gatsby';
export default function Home(props) {
  const [user_roles, setUserRoles] = useState(null)
  const [T, setLang, lang] = useTranslation()
  return (
    <Layoutas
      backgroundColor='#fff'
      getUserRoles={(roles) => {
        setUserRoles((Array.isArray(roles) && roles.length > 0) ? roles[0] : null)
      }}
      getLang={(lang, lang2) => {
        setLang(lang2)
      }}
    >
      <div style={{
        width: '100%',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '85vh',
        backgroundImage: `url(${home})`,
        justifyContent: 'center',
        flexDirection: 'column',
        display: 'flex'
      }}>
        <div className='max-w-[900px] m-auto mt-[500]'>
          <div className='text-4xl lg:text-7xl text-white text-center'> Data Platform</div>
          <div className='text-xl lg:text-4xl text-white text-center'> {T("Home_Description")}</div>
        </div>
      </div>

      <div className="max-w-[1300px] 2xl:max-w-[1400px] m-auto mt-10">
        <h1 className='text-2xl xl:text-4xl'>{T("WHAT_ISEE")}</h1>
        <div className="grid grid-cols-12 gap-8 w-full p-4 lg:p-4">
          <div className="col-span-12 mx-4 md:mx-0 md:col-span-6 lg:col-span-3 flex flex-col bg-white rounded-xl drop-shadow-lg">
            <div className="w-full">
              <img className="flex self-center w-full cursor-pointer rounded-t-xl" onClick={() => {
                navigate('target')
              }} src={introduce1} alt="images" />
            </div>
            <div className='p-2'>
              <div className="text-xl h-[50px]">{T("Target_Group_EEF")}</div>
              <div className="h-[120px] font-baijam">{T("Target_Group_EEF_Detail")}</div>
              <a href='/target' className="text-lg text-green-500">{T("See_More")}</a>
            </div>
          </div>

          <div className="col-span-12 mx-4 md:mx-0 md:col-span-6 lg:col-span-3 flex flex-col bg-white rounded-xl drop-shadow-lg">
            <div className="w-full">
              <img className="flex self-center w-full cursor-pointer rounded-t-xl" onClick={() => {
                navigate('cct')
              }} src={introduce2} alt="images" />
            </div>
            <div className='p-2'>
              <div className="text-xl h-[50px]">{T("Opportunity_Scholarships")}</div>
              <div className="h-[120px] font-baijam"> {T("Opportunity_Scholarships_Detail")}</div>
              <a href='/cct' className="text-lg text-green-500">{T("See_More")}</a>
            </div>
          </div>
          <div className="col-span-12 mx-4 md:mx-0 md:col-span-6 lg:col-span-3 flex flex-col bg-white rounded-xl drop-shadow-lg">
            <div className="w-full">
              <img className="flex self-center w-full cursor-pointer rounded-t-xl" onClick={() => {
                navigate('tsqp')
              }} src={introduce3} alt="images" />
            </div>
            <div className='p-2'>
              <div className="text-xl h-[50px]">{T("Model_Innovations")}</div>
              <div className="h-[120px] font-baijam"> {T("Model_Innovations_Detail")}</div>
              <a href='/tsqp' className="text-lg text-green-500">{T("See_More")}</a>
            </div>
          </div>

          <div className="col-span-12 mx-4 md:mx-0 md:col-span-6 lg:col-span-3 flex flex-col bg-white rounded-xl drop-shadow-lg">
            <div className="w-full">
              <img className="flex self-center w-full cursor-pointer rounded-t-xl" onClick={() => {
                navigate('/student-statistics/country')
              }} src={introduce4} alt="images" />
            </div>
            <div className='p-2'>
              <div className="text-xl h-[50px]">{T("Education_Situation")}</div>
              <div className="h-[120px] font-baijam"> {T("Education_Situation_Detail")}</div>
              <a href='/tsqp' className="text-lg text-green-500">{T("See_More")}</a>
            </div>
          </div>

        </div>

        {
          ['partner', 'eef', 'admin', 'representative'].includes(user_roles) &&
          <>
            <h1 className='text-4xl mt-8'>Featured ที่น่าสนใจ</h1>
            <div className="grid grid-cols-12 gap-8 w-full mt-2 ">
              <div className="col-span-12 mx-4 md:mx-0 lg:col-span-6 flex flex-col items-center justify-center bg-white rounded-xl drop-shadow-lg">
                <div className="w-full">
                  <img className="flex self-center w-full cursor-pointer rounded-t-xl" onClick={() => {
                    navigate('/school')
                  }} src={featuredproduct1} alt="images" />
                </div>
                <div className='p-2'>
                  <div className="text-xl">ค้นหาโรงเรียนจากจุดสนใจ หรือชื่อโรงเรียน</div>
                  <div className='font-baijam'>
                    ระบบการค้นหาโรงเรียนเพื่อใช้เป็นข้อมูลสำหรับการช่วยเหลือกลุ่มเป้าหมายที่อยู่ในโรงเรียน หรือพื้นที่ ตามบริบทที่ต้องการ โดยสามารถค้นหาได้จากบริเวณโดยรอบสถานที่ หรือในพื้นที่ที่สนใจ รวมถึงสามารถค้นหาด้วยรายชื่อโรงเรียนเพื่อดูรายละเอียดของแต่ละ
                    โรงเรียนได้
                  </div>
                  <a href='/school' className="text-sm text-green-500">ดูรายละเอียดเพิ่มเติม</a>
                </div>
              </div>

              <div className="col-span-12 mx-4 md:mx-0 lg:col-span-6 flex flex-col items-center justify-center bg-white rounded-xl drop-shadow-lg">
                <div className="w-full">
                  <img className="flex self-center w-full cursor-pointer rounded-t-xl" onClick={() => {
                    navigate('/fund')
                  }} src={featuredproduct2} alt="images" />
                </div>
                <div className='p-2'>
                  <div className="text-xl">การดำเนินงานในพื้นที่ของท่าน</div>
                  <div className='font-baijam'>
                    การดำเนินงานของ กสศ. ในพื้นที่ของท่าน แสดงการดำเนินงานในพื้นที่ที่ภาคีของ กสศ. ได้ดำเนินงานผ่านทุน/โครงการต่างๆ ของ กสศ. โดยแสดงในมุมมองเชิงสถิติและภูมิสารสนเทศตามพื้นที่ ตำบล อำเภอ และจังหวัด
                  </div>
                  <a href='/fund' className="text-sm text-green-500">ดูรายละเอียดเพิ่มเติม</a>
                </div>
              </div>

            </div>
          </>
        }

        <h1 className='text-xl xl:text-4xl mt-12'>  {T("contact_access")}</h1>
        <div className="grid grid-cols-12 gap-8 w-full mt-2 ">
          <div className="col-span-12 flex flex-col items-center justify-center bg-white rounded-xl drop-shadow-lg">
            <div className="grid grid-cols-12 gap-8 w-full mt-2 ">
              <div className="col-span-12 lg:col-span-6 flex flex-col items-center  h-[300px]">
                <div className="text-xl xl:text-2xl mt-8"> {T("EEF_FULL")} </div>
                <div className="text-lg xl:text-xl text-center">{T("adddress_1")}</div>
                <div className="text-lg xl:text-xl text-center">{T("adddress_2")}</div>
                <div class="grid grid-cols-3 divide-x mt-3">
                  <div className='text-center '>
                    <div>{T("EMAIL")}</div>
                    <div>isee@eef.or.th</div>
                  </div>
                  <div className='text-center'>
                    <div>{T("TEL")}</div>
                    <div> 02-079-5475</div>
                  </div>
                  <div className='text-center'>
                    <div>{T("FEX")}</div>
                    <div> 02-619-1810,02-619-1812</div>
                  </div>
                </div>
                <button class="mt-6 inline-flex text-white bg-green-500 hover:bg-green-800 border-0 py-2 px-6 focus:outline-nonerounded text-lg"
                  onClick={() => {
                    navigate('contact')
                  }}
                >{T("Contact_EEF")}</button>
              </div>
              <div className="col-span-12 lg:col-span-6 flex flex-col items-center  h-[300px]">
                <div className="w-5/6 h-5/6 bg-gray-100" >
                  <iframe className="w-full h-full" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d484.37077112246953!2d100.545463!3d13.780913!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb1b7e1d880341434!2sEquitable%20Education%20Fund!5e0!3m2!1sen!2sth!4v1663312108615!5m2!1sen!2sth"></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h1 className='text-4xl mt-12 text-center'> {T("Partners_with")} </h1>
        <div className="grid grid-cols-12 gap-8 w-full mt-2">
          <div className="col-span-6 lg:col-span-3 flex flex-col items-center">
            <img className="flex self-center w-[120px] xl:w-[150px]"
              src={logo1} alt="images" />
            <div className="text-sm xl:text-lg text-center">{T("DOPA_FULL")}</div>
          </div>
          <div className="col-span-6 lg:col-span-3 flex flex-col items-center">
            <img className=" w-[120px] xl:w-[150px]" src={logo2} alt="images" />
            <div className="text-sm xl:text-lg text-center">{T("BPP_FULL")}</div>
          </div>
          <div className="col-span-6 lg:col-span-3 flex flex-col items-center">
            <img className=" w-[120px] xl:w-[150px]" src={logo3} alt="images" />
            <div className="text-sm xl:text-lg text-center">{T("DLA_FULL")}</div>
          </div>
          <div className="col-span-6 lg:col-span-3 flex flex-col items-center">
            <img className=" w-[120px] xl:w-[150px]" src={logo4} alt="images" />
            <div className="text-sm xl:text-lg text-center">{T("NOB_FULL")}</div>
          </div>
          <div className="col-span-6 lg:col-span-3 flex flex-col items-center">
            <img className=" w-[120px] xl:w-[150px]" src={logo5} alt="images" />
            <div className="text-sm xl:text-lg text-center">{T("OBEC_FULL")}</div>
          </div>
          <div className="col-span-6 lg:col-span-3 flex flex-col items-center">
            <img className=" w-[120px] xl:w-[150px]" src={logo6} alt="images" />
            <div className="text-sm xl:text-lg text-center"> {T("OPEC_FULL")}</div>
          </div>
          <div className="col-span-6 lg:col-span-3 flex flex-col items-center">
            <img className=" w-[120px] xl:w-[150px]" src={logo7} alt="images" />
            <div className="text-sm xl:text-lg text-center"> {T("MOE_FULL")}</div>
          </div>
          <div className="col-span-6 lg:col-span-3 flex flex-col items-center">
            <img className=" w-[120px] xl:w-[150px]" src={logo8} alt="images" />
            <div className="text-sm xl:text-lg text-center"> {T("BKK_FULL")}</div>
          </div>
        </div>

        <div className='h-[100px]'></div>
      </div>
    </Layoutas >
  );
}
